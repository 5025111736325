import { getRequest, ResponseDI } from "../services/urlService";
import { commonUrl } from "../../base/services/urlService";

type GetDictionaryDI = Array<{ Key: string; Value: string }>;

const getDictionary = async () => {
  const url = `${commonUrl}/api/home/GetDic`;
  const request = await getRequest<GetDictionaryDI>(url);
  return request;
};

export default getDictionary;
