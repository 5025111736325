import React from "react";
import { useTranslation } from "react-i18next";
import style from "./Login.module.css";
import logoIcon from "../../base/assets/icons/logo.svg";
import trainIcon from "../../base/assets/icons/iconfinder_train_2875945.svg";
import { useTitle } from "react-use";

const Register: React.FC = (props) => {
  const { t } = useTranslation();
  useTitle(t("General-content-header") + t("Register-Header-Title"));
  return (
    <React.Fragment>
      <div className={style.background}> </div>
      <div className={style.wrapper}>
        <div className={style.registerSuccessBox}>
          <img
            tabIndex={0}
            className={style.logo}
            width="152"
            src={logoIcon}
            alt={t("Header-Logo-alt")}
          />
          <h1 className={style.header}>{t("Login-register-success-header")}</h1>
          <img
            className={style.register__successLogo}
            src={trainIcon}
            alt={t("Register-Success-Logo-alt")}
          />
          <h3 tabIndex={0} className={style.register__success}>
            {t("Login-register-success")}
          </h3>
          <div tabIndex={0} className={style.register__successComment}>
            {t("Login-register-success-comment")}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default Register;
