import { action, makeObservable, observable, runInAction } from "mobx";
import { checkLogin, login as loginService, sendLoginOtp, OtpSendAgain, register as registerService, RegisterDI, sendRegisterOtp, } from "../services/loginService";
import remotedev from "mobx-remotedev";
import { postRequest } from "../services/urlService";
import { commonUrl } from "../services/urlService";
import { forgotPassword, sendForgotPasswordOtp, sendPassword, tokenCheck, } from "../services/forgotPasswordService";

interface UserDI {
  fullName: string;
  token: string;
  isEditor: boolean;
}

interface LogoutResponseDI { }

export interface ResponseDI<T> {
  creationDate: Date;
  errorMessages: string | string[] | null;
  result: T;
  statusCode: number;
}

export class UserStore {
  user!: UserDI | null;
  phone!: string;
  completedOtp: boolean;

  constructor() {
    makeObservable(this, {
      user: observable,
      phone: observable,
      completedOtp: observable,
      setPhone: action,
      logout: action,
      setUser: action,
      setCompletedOtp: action,
      checkLogin: action,
      login: action,
      sendOtpLogin: action,
    });
    this.completedOtp = false;
  }

  logout = async () => {
    await postRequest<{}, ResponseDI<LogoutResponseDI>>(`${commonUrl}/api/user/logout`, {});
    runInAction(() => {
      this.user = null;
    });
    (window as any).gtag("config", "G-SRPQZDZ016", { user_id: "ANONYMOUS" });
  };

  checkLogin = async () => {
    try {
      const { isEditor, token, fullName, userId } = await checkLogin();
      runInAction(() => {
        const user = { isEditor, token, fullName, userId };
        this.setUser(user);
        !!fullName && (window as any).gtag("config", "G-SRPQZDZ016", { user_id: userId });
      });
    } catch (error: any) {
      console.log(error);
    }
  };

  forgotPassword = async (code: string) => {
    await forgotPassword(code);
    this.setCompletedOtp(true);
  };

  sendForgotPasswordOtp = async (email: string) => {
    this.setCompletedOtp(false);
    await sendForgotPasswordOtp(email);
  };

  checkValidToken = async (token: string) => await tokenCheck(token);

  setUser = (user: UserDI) => {
    this.user = user;
  };

  setPhone = (phone: string | undefined) => {
    this.phone = phone ?? "";
  };

  setCompletedOtp = (completed: boolean) => (this.completedOtp = completed);

  resetPassword = async (password: string, token: string) => await sendPassword(password, token);

  sendOtpLogin = async ({ email, password, }: { email: string; password: string | number; }) => {
    this.setCompletedOtp(false);
    const response = await sendLoginOtp({ email, password });
    if (response?.phone) this.setPhone(response.phone);
  };

  OtpSendAgain = async (authType: string) => {
    const capitalizedAuthType = authType.charAt(0).toUpperCase() + authType.slice(1);
    return await OtpSendAgain(`Send${capitalizedAuthType}Otp`);
  };

  login = async ({ code }: { code: string }) => {
    const { token, fullName, isEditor, email, userId } = await loginService({ code });
    runInAction(() => {
      const user = { isEditor, email, token, fullName, userId, };
      this.setUser(user);
      this.setCompletedOtp(true);
      (window as any).gtag("config", "G-SRPQZDZ016", { user_id: userId });
    });
  };

  sendOtpRegister = async ({ email, password, company, name, role, phone, }: RegisterDI) => {
    this.setCompletedOtp(false);
    const response = await sendRegisterOtp({ email, password, name, company, role, phone, });
    if (response?.phone) this.setPhone(response.phone);
  };

  register = async (OtpCode: string) => {
    await registerService({ OtpCode });
    this.setCompletedOtp(true);
  };
}

const withRemoteDev = remotedev(UserStore);
export default new withRemoteDev();
