import { Button, withStyles } from "@material-ui/core";
import React from "react";

type ButtonPropsDI = {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  type?: "submit" | "button";
  children?: any;
  variant?: "filled" | "empty";
  disabled?: boolean;
  "aria-label"?: string;
  id?: string;
} & typeof defaultProps;

const defaultProps = {
  type: "button",
  variant: "filled",
  disabled: false,
};

const ColorButton = withStyles({
  root: {
    fontFamily: "FbReforma-Medium",
    backgroundColor: "#00458a",
    fontSize: "16px",
    color: "white",
    height: "40px",
    minWidth: "150px",
    borderRadius: "20px",
    "&:hover": {
      backgroundColor: "#00458a",
      opacity: 0.65,
    },
    "&:focus": {
      outline: "2px solid #0d6efd",
    },
    "&:disabled": {
      opacity: "35%",
      color: "white",
      backgroundColor: "#00458a",
    },
  },
  outlined: {
    borderColor: "#00458a",
    backgroundColor: "white",
    color: "#00458a",
    "&:hover": {
      opacity: 0.65,
      backgroundColor: "white",
      color: "#00458a",
    },
    "&:focus": {
      borderColor: "rgba(0, 47, 94, 1)",
      backgroundColor: "white",
    },
    "&:disabled": {
      backgroundColor: "white",
      borderColor: "#00458a",
      color: "#00458a",
      opacity: "35%",
    },
  },
})(Button);

const ButtonWrapper = (p: ButtonPropsDI) => {
  const props = {
    ...defaultProps,
    ...p,
  };
  return (
    <ColorButton
      aria-label={props["aria-label"]}
      onClick={props.onClick}
      type={props.type}
      variant={props.variant === "filled" ? "contained" : "outlined"}
      disabled={props.disabled}
      id={props.id ?? ""}
    >
      {props.children}
    </ColorButton>
  );
};

ButtonWrapper.defaultProps = defaultProps;

export default ButtonWrapper;
