import { getRequest, postRequest, ResponseDI } from "./urlService";
import { commonUrl } from "./urlService";
export interface ParamDI {
  [key: string]: any;
}

interface LoginResponseDI {
  token: string;
  userId: number;
  fullName: string;
  isEditor: boolean;
  email?: string;
  errorMessage?: string;
  statusCode: string;
}

interface OtpResponse {
  phone?: string;
  status?: string;
}

export const login = async ({ code }: { code: string }) => {
  const response = await postRequest<
    { Code: string },
    ResponseDI<LoginResponseDI>
  >(`${commonUrl}/api/user/login`, { Code: code });
  return response.result;
};

export const sendLoginOtp = async ({
  email,
  password,
}: {
  email: string;
  password: string | number;
}) => {
  const response = await postRequest<
    { username: string; password: string | number },
    ResponseDI<OtpResponse>
  >(`${commonUrl}/api/user/SendLoginOtp`, { username: email, password });
  if (response?.statusCode === 0)
    return { status: "ok", phone: response.result.phone };
  return response.result;
};

export const OtpSendAgain = async (authTypeOtp: string) => {
  const response = await postRequest<{}, ResponseDI<OtpResponse>>(
    `${commonUrl}/api/user/${authTypeOtp}`,
    {}
  );
  return response.result;
};

export const checkLogin = async () => {
  const response = await postRequest<{}, ResponseDI<LoginResponseDI>>(
    `${commonUrl}/api/user/CheckLogin`,
    {},
    {},
    false
  );
  return response.result;
};

export const checkLoginWithoutRedirect = async () => {
  const response = await postRequest<{}, ResponseDI<LoginResponseDI>>(
    `${commonUrl}/api/user/CheckLogin`,
    {},
    {},
    true
  );
  return response.result;
};

export interface RegisterDI {
  name: string;
  password: string | number;
  email: string;
  role: string;
  phone: string | number;
  company: string;
}

export const sendRegisterOtp = async ({
  email,
  password,
  role,
  name,
  company,
  phone,
}: RegisterDI) => {
  const response = await postRequest<RegisterDI, ResponseDI<OtpResponse>>(
    `${commonUrl}/api/user/SendRegisterOtp`,
    { role, email, password, name, phone, company }
  );
  if (response?.statusCode === 0)
    return { status: "ok", phone: response.result.phone };
  return response.result;
};

export const register = async ({ OtpCode }: { OtpCode: string }) => {
  const response = await postRequest<
    { OtpCode: string },
    ResponseDI<LoginResponseDI>
  >(`${commonUrl}/api/user/register`, { OtpCode });
  return response.result;
};
