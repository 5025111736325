import React from "react";
import Loader from "../loader/Loader";
import styles from "./GlobalLoader.module.css";
import appStore from "../stores/AppStore";
import { observer } from "mobx-react";
interface LoaderProps {
  [index: string]: any;
}
const GlobalLoader: React.FC<LoaderProps> = (props) => {
  if (props.showAnyway) {
    return (
        <div
          className={styles.globalLoaderWrapper}
          style={{
            display: "block",
          }}
        >
          <Loader className={styles.globalLoader}></Loader>
        </div>
      );
  }
  return (
    <div
      className={styles.globalLoaderWrapper}
      style={{
        display: !appStore.showGlobalLoader ? "none" : "block",
      }}
    >
      <Loader className={styles.globalLoader}></Loader>
    </div>
  );
};
export default observer(GlobalLoader);
