import { useTranslation } from "react-i18next";
import style from "./Login.module.css";
import logoIcon from "../../base/assets/icons/logo.svg";
import { Modal, Typography } from "@material-ui/core";
import Button from "../../base/reusables/button/Button";
import ModalButton from "../../base/reusables/button/Button";
import { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { Grid } from "@material-ui/core";
import AppStore from "../../base/stores/AppStore";
import UserStore from "../../base/stores/UserStore";
import { useHistory } from "react-router-dom";
import { checkLoginWithoutRedirect } from "../../base/services/loginService";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme: any) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    position: "absolute",
    paddingTop: "77px",
    height: "254px",
    width: "512px",
    backgroundColor: "rgba(255, 255, 255, 1)",
    borderRadius: "13px 13px 0 0",
    boxShadow: "0 25px 31px 0 rgba(0, 0, 0, 0.2)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderBottomLeftRadius: "50% 40px !important",
    borderBottomRightRadius: "50% 40px !important",
    "@media (max-width: 600px)": {
      width: "80vw",
    },
  },
  modalBackdrop: {
    backgroundColor: "rgba(0, 15, 30, 0.81)",
    "& div:first-child": {
      backgroundColor: "unset !important",
    },
  },
  title: {
    textAlign: "center",
    fontFamily: "FbReforma",
    fontSize: "25px",
    color: "rgba(40, 40, 40, 1)",
    letterSpacing: "0px",
    marginBottom: "41px",
    "@media (max-width: 600px)": {
      fontSize: "16px",
    },
  },
}));

const ForgotPassword: React.FC = (props) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [modalOpen, setModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [email, setEmail] = useState<string>("");
  const [isLoggedIn, setIsLoggedin] = useState<boolean>(false);

  const classes = useStyles();

  useEffect(() => {
    checkUserLogin();
  }, []);

  const setFocus = (errors: any) =>
    document.getElementById(errors[0].props.id)?.focus();

  const checkUserLogin = async () => {
    try {
      const { token } = await checkLoginWithoutRedirect();
      if (token) {
        setErrorMessage(t("User-logged-in-error"));
        setModalOpen(true);
        setIsLoggedin(true);
        return;
      }
    } catch (error: any) {
      if (error.statusCode < 400) {
        setErrorMessage(error.errorMessages);
      } else {
        setErrorMessage(t("GeneralError"));
      }
      setModalOpen(true);
    }
  };

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    try {
      AppStore.incrementLoadingProcedure();
      await UserStore.sendForgotPasswordOtp(email);
      history.push("/one-time-password/forgotPassword");
    } catch (error: any) {
      if (error.statusCode < 400) {
        setErrorMessage(error.errorMessages);
      } else {
        setErrorMessage(t("GeneralError"));
      }
      setModalOpen(true);
    } finally {
      AppStore.decrementLoadingProcedure();
    }
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const redirect = () => {
    setModalOpen(false);
    history.push(`${isLoggedIn ? "/" : "/login"}`);
  };

  return (
    <>
      {!isLoggedIn && (
        <div className={style.background}>
          <div className={style.wrapper}>
            <div className={style.resetPassBox}>
              <img
                tabIndex={0}
                className={style.logo}
                width="152"
                src={logoIcon}
                alt={t("Header-Logo-alt")}
              />
              <h3 className={style.header}>{t("Forgot-password-header")}</h3>
              <span>{t("Reset-password-set-email-to-reset")}</span>
              <ValidatorForm
                onSubmit={handleSubmit}
                onError={(errors) => setFocus(errors)}
              >
                <Grid container>
                  <Grid className={style.register__field} xs={12} sm={8} item>
                                      <TextValidator
                      onChange={handleEmailChange}
                      value={email}
                      name="email"
                      fullWidth
                      id="email"
                      style={{ direction: "ltr" }}
                      label={t("Login-mail")}
                      validators={["required", "isEmail"]}
                      errorMessages={[
                        t("Validation-Required"),
                        t("Validation-InvalidEmail"),
                      ]}
                    />
                  </Grid>
                  <Grid className={style.resetPassButton} xs={12} sm={4} item>
                    <Button type="submit" aria-label={t("Reset-password-send")}>{t("Reset-password-send")}</Button>
                  </Grid>
                </Grid>
              </ValidatorForm>
            </div>
          </div>
        </div>
      )}
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={modalOpen}
        onClose={redirect}
        className={classes.modalBackdrop}
        BackdropProps={{
          classes: {
            root: classes.modalBackdrop,
          },
        }}
      >
        <div style={getModalStyle()} className={classes.paper}>
          <Typography variant="h6" id="modal-title" className={classes.title}>
            {errorMessage}
          </Typography>
          <ModalButton aria-label={errorMessage} onClick={redirect}>
            {t("got-it")}
          </ModalButton>
        </div>
      </Modal>
    </>
  );
};
export default ForgotPassword;
