import { useTranslation } from "react-i18next";
import style from "./Login.module.css";
import logoIcon from "../../base/assets/icons/logo.svg";
import { Modal, Typography, Button } from "@material-ui/core";
import ModalButton from "../../base/reusables/button/Button";
import { useEffect, useState, useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { Grid } from "@material-ui/core";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useHistory, useLocation } from "react-router-dom";
import AppStore from "../../base/stores/AppStore";
import UserStore from "../../base/stores/UserStore";
import { checkLoginWithoutRedirect } from "../../base/services/loginService";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme: any) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    position: "absolute",
    paddingTop: "77px",
    height: "254px",
    width: "512px",
    backgroundColor: "rgba(255, 255, 255, 1)",
    borderRadius: "13px 13px 0 0",
    boxShadow: "0 25px 31px 0 rgba(0, 0, 0, 0.2)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderBottomLeftRadius: "50% 40px !important",
    borderBottomRightRadius: "50% 40px !important",
    "@media (max-width: 600px)": {
      width: "80vw",
    },
  },
  modalBackdrop: {
    backgroundColor: "rgba(0, 15, 30, 0.81)",
    "& div:first-child": {
      backgroundColor: "unset !important",
    },
  },
  title: {
    textAlign: "center",
    fontFamily: "FbReforma",
    fontSize: "25px",
    color: "rgba(40, 40, 40, 1)",
    letterSpacing: "0px",
    marginBottom: "41px",
    "@media (max-width: 600px)": {
      fontSize: "16px",
    },
  },
}));

const ResetPassword: React.FC = (props) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [modalOpen, setModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [password, setPassword] = useState("");
  const [showPass, setShowPass] = useState<boolean>(false);
  const [token, setToken] = useState<string>("");
  const { search } = useLocation();
  const [isLoggedIn, setIsLoggedin] = useState<boolean>(false);
  const params = new URLSearchParams(search);

  const classes = useStyles();

  useEffect(() => {
    checkUserLogin();
  }, []);

  const setFocus = (errors: any) =>
    document.getElementById(errors[0].props.id)?.focus();

  const checkUserLogin = async () => {
    try {
      const { token } = await checkLoginWithoutRedirect();
      if (token) {
        setErrorMessage(t("User-logged-in-error"));
        setModalOpen(true);
        setIsLoggedin(true);
        return;
      }
      checkValidToken();
    } catch (error: any) {
      if (error.statusCode > 400) {
        setErrorMessage(t("GeneralError"));
      } else {
        setErrorMessage(error.errorMessages);
      }
      setModalOpen(true);
    }
  };

  useEffect(() => {
    ValidatorForm.addValidationRule("maxLength", (value) => {
      if (value?.length > parseInt(t("Password-max-chars"))) {
        return false;
      }
      return true;
    });
    ValidatorForm.addValidationRule("minLength", (value) => {
      if (value?.length < parseInt(t("Password-min-chars"))) {
        return false;
      }
      return true;
    });
    return () => {
      ValidatorForm.removeValidationRule("maxLength");
      ValidatorForm.removeValidationRule("minLength");
    };
  }, []);

  const checkValidToken = async () => {
    try {
      const token = params.get("t");
      if (!token) return history.push("/login");

      await UserStore.checkValidToken(token.toString());
      setToken(token.toString());
    } catch (error: any) {
      if (error.statusCode < 400) {
        setErrorMessage(error.errorMessages);
      } else {
        setErrorMessage(t("GeneralError"));
      }
      setModalOpen(true);
    }
  };

  const redirect = () => {
    setModalOpen(false);
    history.push(`${isLoggedIn ? "/" : "/login"}`);
  };

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    try {
      AppStore.incrementLoadingProcedure();
      await UserStore.resetPassword(password, token);
      setErrorMessage(t("Reset-password-success"));
      setModalOpen(true);
      return;
    } catch (error: any) {
      if (error.statusCode < 400) {
        setErrorMessage(error.errorMessages);
      } else {
        setErrorMessage(t("GeneralError"));
      }
      setModalOpen(true);
    } finally {
      AppStore.decrementLoadingProcedure();
    }
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  return (
    <>
      {token && (
        <div className={style.background}>
          <div className={style.wrapper}>
            <div className={style.resetPassBox}>
              <img
                tabIndex={0}
                className={style.logo}
                width="152"
                src={logoIcon}
                alt={t("Header-Logo-alt")}
              />
              <h3 className={style.header}>{t("Reset-password-header")}</h3>
              <ValidatorForm
                onSubmit={handleSubmit}
                onError={(errors) => setFocus(errors)}
              >
                <Grid container>
                  <Grid className={style.register__field} xs={12}>
                    <TextValidator
                      onChange={handlePasswordChange}
                      value={password}
                      type={showPass ? "text" : "password"}
                      name="password"
                      fullWidth
                      id="password"
                      label={t("Reset-password-new-pass")}
                      validators={["required", "maxLength", "minLength"]}
                      errorMessages={[
                        t("Validation-Required"),
                        `${t("Validation-MaximumLength")} ${t(
                          "Password-max-chars"
                        )}`,
                        `${t("Validation-MinimumLength")}  ${t(
                          "Password-min-chars"
                        )}`,
                      ]}
                    />
                    {showPass ? (
                      <VisibilityIcon
                        className={style.showPass}
                        onClick={() => setShowPass(!showPass)}
                        fontSize="small"
                      />
                    ) : (
                      <VisibilityOffIcon
                        className={style.showPass}
                        onClick={() => setShowPass(!showPass)}
                        fontSize="small"
                      />
                    )}
                  </Grid>
                </Grid>
                <Button
                  className={style.resetPassButton}
                  aria-label={t("Reset-password-send")}
                  type="submit"
                >
                  {t("Reset-password")}
                </Button>
              </ValidatorForm>
            </div>
          </div>
        </div>
      )}
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={modalOpen}
        onClose={redirect}
        className={classes.modalBackdrop}
        BackdropProps={{
          classes: {
            root: classes.modalBackdrop,
          },
        }}
      >
        <div style={getModalStyle()} className={classes.paper}>
          <Typography variant="h6" id="modal-title" className={classes.title}>
            {errorMessage}
          </Typography>
          <ModalButton aria-label={errorMessage} onClick={redirect}>
            {t("got-it")}
          </ModalButton>
        </div>
      </Modal>
    </>
  );
};
export default ResetPassword;
