import React from "react";
import ReactDOM from "react-dom";
import "./base/assets/styles/index.css";
import "./base/assets/icomoon/style.css";
import "./base/assets/styles/icons.css";
import CssBaseline from "@material-ui/core/CssBaseline";
import Router from "./base/router/Router";
import "./i18n";
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import { createTheme, ThemeProvider } from "@material-ui/core";
import { create } from "jss";
import rtl from "jss-rtl";
import { StylesProvider, jssPreset } from "@material-ui/core/styles";
import GlobalLoader from "./base/globalLoader/GlobalLoader";

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

const theme = createTheme({
  typography: {
    fontFamily: `"FbReforma"`,
  },
  palette: {
    background: {
      default: "rgba(255, 255, 255, 1);",
    },
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  direction: "rtl",
  overrides: {
    MuiFormLabel: {
      root: {
        "&.Mui-error": {
          color: "rgba(117, 117, 117, 1);",
        },
      },
    },
  },
});

const container = document.getElementById("root");
ReactDOM.render(
  <ThemeProvider theme={theme}>
    <StylesProvider jss={jss}>
      <React.StrictMode>
        <BrowserRouter>
          <CssBaseline />
          <GlobalLoader></GlobalLoader>
          <Router />
        </BrowserRouter>
      </React.StrictMode>
    </StylesProvider>
  </ThemeProvider>,
  container
);

reportWebVitals();
