export class RequestError extends Error {
  statusCode: number;
  errorMessages: string | string[] | null;
  constructor({
    message,
    statusCode,
    errorMessages,
  }: {
    message: string;
    statusCode: number;
    errorMessages: string | string[] | null;
  }) {
    super(message); // (1)
    this.name = "RequestError"; // (2)

    this.statusCode = statusCode;
    this.errorMessages = errorMessages;
  }
}

export interface ResponseDI<T> {
  creationDate: Date;
  errorMessages: string | string[] | null;
  result: T;
  statusCode: number;
}
export const commonUrl = process.env.REACT_APP_API_URL;
export const headers = {
  "Ocp-Apim-Subscription-Key": process.env.REACT_APP_API_KEY ?? "",
};
async function http<T>(
  path: string,
  config: RequestInit,
  disableRedirect?: boolean
): Promise<T> {
  try {
    const request = new Request(path, {
      credentials: "include",
      headers: headers,
      ...config,
    });
    const response = await fetch(request);
    if (!response.ok || response.status >= 400) {
      throw new RequestError({
        message: "Error from http function",
        statusCode: response.status,
        errorMessages: response.statusText
          ? response.statusText
          : "An Error has occured",
      });
    }
    var body = await response.json();
    if (body.statusCode && body.statusCode != 0) {
      if (
        body?.statusCode == 1 &&
        !body?.errorMessages?.includes("NoContent")
      ) {
        if (!disableRedirect) {
          const login = "/login";
          const link =
            window.location.pathname !== "/" && window.location.pathname !== ""
              ? "?redirect=" + window.location.pathname
              : "";
          window.location.href = login + link;
        } else {
          return body;
        }
      }

      throw new RequestError({
        message: Array.isArray(body.errorMessages)
          ? body.errorMessages.toString()
          : body.errorMessages,
        statusCode: body.statusCode,
        errorMessages: body.errorMessages,
      });
    }
    return body;
  } catch (error: any) {
    if (!body) {
      await delay(400);
    }
    throw error;
  }
}
export async function getRequest<T>(
  path: string,
  config?: RequestInit
): Promise<T> {
  const init = { method: "GET", ...config };

  return await http<T>(path, init);
}

export async function deleteRequest<T>(
  path: string,
  config?: RequestInit
): Promise<T> {
  const init = { method: "DELETE", ...config };
  return await http<T>(path, init);
}
export async function postRequest<T, U>(
  path: string,
  body: T,
  config?: RequestInit,
  disableRedirect?: boolean
): Promise<U> {
  const conf = config
    ? config
    : {
        headers: {
          "Content-Type": "application/json",
          ...headers,
        },
      };
  const init = { method: "POST", body: JSON.stringify(body), ...conf };
  return await http<U>(path, init, disableRedirect);
}
export async function putRequest<T, U>(
  path: string,
  body: T,
  config?: RequestInit
): Promise<U> {
  const init = { method: "put", body: JSON.stringify(body), ...config };
  return await http<U>(path, init);
}

export function isAbsoluteUrl(url: string) {
  return url.charAt(0) !== "/";
}

export const getQueryStringFromObject = (obj: { [index: string]: any }) => {
  var str = "";
  for (var key in obj) {
    if (str != "") {
      str += "&";
    }
    str += key + "=" + encodeURIComponent(obj[key]);
  }
  return str;
};

/**
 * Expects a string of values separated by commas
 */
export function getArrayFromQueryParam(queryParam: string | null) {
  if (!queryParam || !queryParam.length) return [];

  return queryParam.split(",");
}

const delay = (mil: number) => {
  return new Promise<void>((res) => {
    setTimeout(() => {
      res();
    }, mil);
  });
};
