import React, { Suspense, useEffect, useState } from "react";
import { lazyWithRetry } from "./LazyWithRetry";
import { Switch, Route } from "react-router-dom";
import ErrorBoundary from "../../base/error-boundary/ErrorBoundary";
import RegisterSuccess from "../../modules/login/RegisterSuccess";
import GlobalLoader from "../globalLoader/GlobalLoader";
import AppStore, { SiteMapItemDI } from "../stores/AppStore";
import { useTranslation } from "react-i18next";
import { routesType } from "./staticRoutes";
import { observer } from "mobx-react";
import ResetPassword from "../../modules/login/ResetPassword";
import ForgotPassword from "../../modules/login/ForgotPassword";
import ForgotPasswordSuccess from "../../modules/login/ForgotPasswordsSuccess";

const NotFound = lazyWithRetry(() => import("../not-found/NotFound"));
const Home = lazyWithRetry(() => import("../../modules/home/Home"));
const Lesson = lazyWithRetry(() => import("../../modules/lesson/LessonRoutes"));
const Login = lazyWithRetry(() => import("../../modules/login/Login"));
const Register = lazyWithRetry(() => import("../../modules/login/Register"));
const Contact = lazyWithRetry(() => import("../../modules/contact/Contact"));
const ContactSuccess = lazyWithRetry(() => import("../../modules/contact/Contact-success"));
const GeneralContent = lazyWithRetry(() => import("../../modules/general-content/GeneralContent"));
const OneTimePassword = lazyWithRetry(() => import("../../modules/login/OneTimePassword"));
const Search = lazyWithRetry(() => import("../../modules/search/SearchPage"));

const Router: React.FC = (props) => {
  const [routes, setRoutes] = useState<any>([]);
  const { t } = useTranslation();

  useEffect(() => {
    const links = AppStore.siteMapList.map((item: SiteMapItemDI) => ({
      type: item.contentType,
      url: item.url,
    }));
    setRoutes(links);
  }, [AppStore.siteMapList]);

  const getComponentUrl: any = (type: string) => {
    let pathName;
    if (routes.length) {
      const componentUrl = routes.find((route: any) => route.type === type);
      AppStore.setLessonsRoute(componentUrl?.url);
      pathName = componentUrl?.url;
    }
    return pathName || "/not-found";
  };

  return (
    <>
      <ErrorBoundary>
        <Suspense fallback={<GlobalLoader showAnyway={true} />}>
          <Switch>
            <Route exact path="/one-time-password/:authType">
              <OneTimePassword />
            </Route>
            <Route
              component={Contact}
              exact
              path={getComponentUrl(routesType.contactUs)}
            />
            <Route component={ContactSuccess} exact path="/contact-success" />
            <Route exact path="/register">
              <Register />
            </Route>
            <Route exact path="/register-success">
              <RegisterSuccess />
            </Route>
            <Route exact path="/forgot-password">
              <ForgotPassword />
            </Route>
            <Route exact path="/forgot-password-success">
              <ForgotPasswordSuccess />
            </Route>
            <Route exact path="/reset-password">
              <ResetPassword />
            </Route>
            <Route exact path="/login">
              <Login />
            </Route>
            <Route exact component={Home} path="/" />
            <Route path={getComponentUrl(routesType.lessons)}>
              <Lesson />
            </Route>
            <Route path={`/${t("Search-path")}`} component={Search} />
            <Route path="*">
              <GeneralContent />
            </Route>
          </Switch>
        </Suspense>
      </ErrorBoundary>
    </>
  );
};

export default observer(Router);
