import React from "react";
import Lottie from "react-lottie";
import animationData from "./loader.json";
import styles from './loader.module.css';

interface Propable {
  children?: React.ReactChildren;
  [index:string]:any
}
const Loader: React.FC<Propable> = (props) => {
  const children = props.children || null
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
  };

  return (
    <>
      <div {...props} >
        <Lottie
          options={defaultOptions}
          isStopped={false}
          isPaused={false}
        />
      </div>
      {children}
    </>
  );
};
export default Loader;
