import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import enFile from "./base/assets/locales/en/translation.json";
import heFile from "./base/assets/locales/he/translation.json";
import arFile from "./base/assets/locales/ar/translation.json";
import ruFile from "./base/assets/locales/ru/translation.json";

let lang = "he";
lang = lang.split("-")[0];
const Languages = ["en", "he", "ru", "ar"];
const resources = {
  en: {
    translation: enFile,
  },
  he: {
    translation: heFile,
  },
  ar: {
    translation: arFile,
  },
  ru: {
    translation: ruFile,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: Languages.includes(lang) ? lang : "he",
    debug: true,
    whitelist: Languages,
    react: {
      useSuspense: false,
    },
  });

const origGetSuffix = i18n.services.pluralResolver.getSuffix;
i18n.services.pluralResolver.getSuffix = (code, count, ...other) => {
  if (code === "he") {
    if (count > 1) {
      return "_plural";
    }
    return `_${count}`;
  }
  return origGetSuffix.call(
    i18n.services.pluralResolver,
    code,
    count,
    ...other
  );
};

export default i18n;
