import { getRequest, postRequest, ResponseDI } from "./urlService";
import { commonUrl } from "./urlService";

interface forgotPasswordDI {
  OtpCode: string;
}

interface sendForgotPasswordOtpDI {
  Email: string;
}

interface checkTokenDI {
  Token: string;
}

interface resetPasswordDI {
  NewPassword: string;
  Token: string;
}

export const forgotPassword = async (code: string) =>
  await postRequest<forgotPasswordDI, forgotPasswordDI>(
    `${commonUrl}/api/user/forgotPassword`,
    { OtpCode: code }
  );

export const sendForgotPasswordOtp = async (email: string) =>
  await postRequest<sendForgotPasswordOtpDI, sendForgotPasswordOtpDI>(
    `${commonUrl}/api/user/sendForgotPasswordOtp`,
    { Email: email }
  );

export const tokenCheck = async (token: string) =>
  await postRequest<checkTokenDI, checkTokenDI>(
    `${commonUrl}/api/user/isTokenValid`,
    { Token: token }
  );

export const sendPassword = async (password: string, token: string) =>
  await postRequest<resetPasswordDI, resetPasswordDI>(
    `${commonUrl}/api/user/ResetPassword`,
    { Token: token, NewPassword: password }
  );
