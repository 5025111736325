import { action, computed, makeObservable, observable, runInAction, } from "mobx";
import isMobile from "../services/mobileDetector";
import remotedev from "mobx-remotedev";
import getDictionary from "../services/getDictionary";
import i18n from "../../i18n";
import initData from "../services/getInitData";

if ("documentMode" in window.document) {
  const { configure } = require("mobx");
  configure({
    useProxies: "never",
  });
}

export interface MenuItemDI {
  id?: number;
  text: string;
  link: string | null;
  isNewTab: boolean;
  links: Array<MenuItemDI> | null;
  url?: string | null;
}

export interface SiteMapItemDI {
  breadCrumbs: Array<BreadcrumbsDI>;
  contentType: string;
  pageId: number;
  text: string;
  url: string;
  Id: number;
}

export interface BreadcrumbsDI {
  route?: string;
  name: string;
  isLast?: boolean;
}

export interface StepItem {
  text: string;
  link: string;
  isNewTab: boolean;
}

interface SubLinkItemI {
  text: string;
  link: string;
  isNewTab: boolean;
  links: null;
}

interface SubLinkI {
  text: string;
  link: null;
  isNewTab: boolean;
  links: Array<SubLinkItemI>;
}

interface ListsFilObj {
  l: Array<ListsFilItem>;
  t: string;
  show: boolean;
}

interface ListsFilItem {
  id: number;
  name: string;
  title: string;
  count: number;
}

interface ArticlesGalleryItemI {
  title: string;
  date: string | null;
  image: string;
  text: string;
  link: string;
  isNewTab: boolean;
}

interface MovieItemI {
  vimeo_movie_url: string;
  title: string;
  movie: string;
  image: string;
}

class AppStore {
  initDataLoaded: boolean = false;
  loadingProcedures: number;
  dir: string = "rtl";
  lang: string = "he";
  isMobile = isMobile();
  screenWidth = window.innerWidth;
  isHeaderVisible = true;
  header!: {
    logoUrl: string;
    title: string;
    subTitle: string;
    links: Array<MenuItemDI>;
  };
  footer!: {
    logoUrl: string;
    links: Array<MenuItemDI>;
  };
  steps!: Array<StepItem>;
  links!: Array<SubLinkI>;
  searchFields!: Array<ListsFilObj>;
  articles!: Array<ArticlesGalleryItemI>;
  movieGallery!: Array<MovieItemI>;
  showMovieGallery: boolean;
  showFreeSearch: boolean;
  headerHeight: number | undefined;
  imageMaxWidth: number;
  imageMaxHeight: number;
  lessonsRoute: string;
  siteMapList: Array<SiteMapItemDI>;
  linksTitle: string = "";
  movieGalleryTitle: string = "";
  prjectStepsTitle: string = "";
  relatedArticlesTitle: string = "";
  searchTitle: string = "";
  lastFocusedElement: any = null;

  constructor() {
    this.loadingProcedures = 0;
    this.header = {
      logoUrl: "",
      title: "",
      subTitle: "",
      links: [],
    };
    this.footer = {
      logoUrl: "",
      links: [],
    };
    this.steps = [];
    this.links = [];
    this.searchFields = [];
    this.articles = [];
    this.movieGallery = [];
    this.showMovieGallery = false;
    this.showFreeSearch = false;
    this.headerHeight = 0;
    this.imageMaxHeight = 0;
    this.imageMaxWidth = 0;
    this.lessonsRoute = "";
    this.siteMapList = [];
    makeObservable(this, {
      loadingProcedures: observable,
      showGlobalLoader: computed,
      dir: observable,
      lang: observable,
      isMobile: observable,
      screenWidth: observable,
      isHeaderVisible: observable,
      footer: observable,
      header: observable,
      steps: observable,
      links: observable,
      searchFields: observable,
      articles: observable,
      movieGallery: observable,
      showMovieGallery: observable,
      showFreeSearch: observable,
      headerHeight: observable,
      imageMaxWidth: observable,
      lessonsRoute: observable,
      imageMaxHeight: observable,
      isMobileResolution: computed,
      setScreenWidth: action,
      getInitData: action,
      getDictionary: action,
      incrementLoadingProcedure: action,
      decrementLoadingProcedure: action,
      setHeaderHeight: action,
      siteMapList: observable,
      movieGalleryTitle: observable,
      linksTitle: observable,
      prjectStepsTitle: observable,
      relatedArticlesTitle: observable,
      searchTitle: observable,
      lastFocusedElement: observable,
      set_lastFocusedElement: action
    });
    window.addEventListener("resize", () => {
      this.setScreenWidth(window.innerWidth);
    });
  }

  setScreenWidth = (width: number) => {
    this.screenWidth = width;
  };

  get isMobileResolution() {
    return this.screenWidth <= 960;
  }

  get showGlobalLoader() {
    return this.loadingProcedures > 0;
  }

  decrementLoadingProcedure = () => {
    this.loadingProcedures--;
  };

  incrementLoadingProcedure = () => {
    this.loadingProcedures++;
  };

  getDictionary = async () => {
    try {
      const dictionary = await getDictionary();

      let dic: { [index: string]: string } = {};
      for (let item of dictionary) {
        dic[item.Key] = item.Value;
      }
      i18n.addResources("he", "translation", dic);
    } catch (error) {
      console.error(error, "Error fetching dictionary, staying with fallback");
    }
  };

  updateSiteMap = (newItem: SiteMapItemDI) => this.siteMapList.push(newItem);

  setLessonsRoute = (path: string) => (this.lessonsRoute = path);

  setHeaderHeight = (headerHeight: number | undefined) => {
    this.headerHeight = headerHeight;
  };

  set_lastFocusedElement = (lastFocusedElement: any) => {
    this.lastFocusedElement = lastFocusedElement;
  };

  getInitData = async () => {
    try {
      const result = await Promise.all([initData(), this.getDictionary()]);
      const { footer, header, steps, links, searchFields, articles, movieGallery, showMovieGallery, showFreeSearch, imageMaxWidth, imageMaxHeight, siteMap, searchTitle, lastFocusedElement, relatedArticlesTitle, prjectStepsTitle, movieGalleryTitle, linksTitle, } = result[0];
      runInAction(() => {
        this.initDataLoaded = true;
        this.footer = {
          ...footer,
          links: footer.menuCats,
        };
        this.siteMapList = siteMap;
        this.header = {
          ...header,
          links: header.menuCats,
        };
        this.steps = steps;
        this.links = links;
        this.searchFields = searchFields;
        this.articles = articles;
        this.movieGallery = movieGallery;
        this.showMovieGallery = showMovieGallery;
        this.showFreeSearch = showFreeSearch;
        this.imageMaxWidth = imageMaxWidth;
        this.imageMaxHeight = imageMaxHeight;
        this.searchTitle = searchTitle;
        this.lastFocusedElement = lastFocusedElement;
        this.relatedArticlesTitle = relatedArticlesTitle;
        this.prjectStepsTitle = prjectStepsTitle;
        this.movieGalleryTitle = movieGalleryTitle;
        this.linksTitle = linksTitle;
      });
    } catch (error: any) {
      throw error;
    }
  };
}

const withRemoteDeov = remotedev(AppStore);
export default new withRemoteDeov();
