import React, { Component, ErrorInfo, ReactNode } from "react";
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router-dom";
import CustomModal from "../modal/Modal";
import { withTranslation, WithTranslation } from "react-i18next";
import userStore from "../../base/stores/UserStore";

interface State {
  hasError: boolean;
  errorStatusCode: number;
  errorMessage: string;
  error?: any;
}

// Type whatever you expect in 'this.props.match.params.*'
type PathParamsType = {
  param1?: "";
};

// Your component own properties
type Props = RouteComponentProps<PathParamsType> & {
  children: ReactNode;
};

interface TranslationProps extends WithTranslation {}

type ErrorBoundaryProps = Props & TranslationProps;

export function useErrorHandler<P = Error>(
  givenError?: P | null | undefined
): React.Dispatch<React.SetStateAction<P | null>> {
  const [error, setError] = React.useState<P | null>(null);
  if (givenError) throw givenError;
  if (error) throw error;
  return setError;
}
class ErrorBoundary extends Component<
  React.PropsWithRef<React.PropsWithChildren<ErrorBoundaryProps>>,
  State
> {
  public state: State = {
    hasError: false,
    errorStatusCode: 0,
    errorMessage: "",
  };

  public static getDerivedStateFromError(err: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: any, errorInfo: ErrorInfo) {
    this.setState({
      error: error,
      errorStatusCode: error.statusCode,
      errorMessage: error.errorMessages,
    });
    console.error("Error from error boundary:", error, errorInfo);
  }

  handleModalStatus = () => {
    if (this.state.errorStatusCode === 1) {
      userStore.logout();
    } else {
      location.reload();
    }
  };

  render() {
    if (
      this.state.hasError &&
      this.state.errorMessage !== "UnAuthorized" &&
      this.state.errorMessage !== "User is logged out"
    ) {
      return (
        <CustomModal
          text={
            this.state.errorStatusCode < 400
              ? this.state.errorMessage
              : this.props.t("GeneralError")
          }
          isOpen={this.state.hasError}
          handleModalStatus={this.handleModalStatus}
        />
      );
    }
    return this.props.children;
  }
}

export default withTranslation()(withRouter(ErrorBoundary));
