import React, { useState } from "react";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "../../base/reusables/button/Button";
import { useTranslation } from "react-i18next";
interface ModalPropsDI {
  text: string;
  isOpen?: boolean;
  handleModalStatus?: (errorMode: boolean) => void;
}

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme: any) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    position: "absolute",
    paddingTop: "77px",
    outline: "none",
    height: "254px",
    width: "512px",
    backgroundColor: "rgba(255, 255, 255, 1)",
    borderRadius: "13px 13px 0 0",
    boxShadow: "0 25px 31px 0 rgba(0, 0, 0, 0.2)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderBottomLeftRadius: "50% 40px !important",
    borderBottomRightRadius: "50% 40px !important",
    "@media (max-width: 600px)": {
      width: "80vw",
    },
  },
  modalBackdrop: {
    backgroundColor: "rgba(0, 15, 30, 0.81)",
    "& div:first-child": {
      backgroundColor: "unset !important",
    },
  },
  title: {
    textAlign: "center",
    fontFamily: "FbReforma",
    fontSize: "25px",
    color: "rgba(40, 40, 40, 1)",
    letterSpacing: "0px",
    marginBottom: "41px",
    "@media (max-width: 600px)": {
      fontSize: "16px",
    },
  },
}));

const CustomModal: React.FC<ModalPropsDI> = (props) => {
  const [modalOpen, setModalOpen] = useState(true);
  const { t } = useTranslation();

  const classes = useStyles();

  React.useEffect(() => {
    // Update the document title using the browser API
    let isOpen = props.isOpen !== undefined ? props.isOpen : modalOpen;
    if (isOpen) {
      setTimeout(() => {
        let el = document.getElementById("modal-title");
        el?.focus();
      }, 0);
    }
  }, [props.isOpen, modalOpen]);

  const handleOpenStatus = () => {
    if (props.isOpen !== undefined && props.handleModalStatus !== undefined) {
      props.isOpen
        ? props.handleModalStatus(false)
        : props.handleModalStatus(true);
    } else {
      setModalOpen(!modalOpen);
    }
  };

  return (
    <Modal
      open={props.isOpen !== undefined ? props.isOpen : modalOpen}
      onClose={handleOpenStatus}
      className={classes.modalBackdrop}
      BackdropProps={{
        classes: {
          root: classes.modalBackdrop,
        },
      }}
    >
      <div style={getModalStyle()} className={classes.paper}>
        <Typography
          variant="h6"
          id="modal-title"
          className={classes.title}
          tabIndex={-1}
        >
          {props.text}
        </Typography>
        <Button
          aria-label={t("Custom-Modal-Ok")}
          onClick={handleOpenStatus}
          variant="empty"
        >
          {t("Custom-Modal-Ok")}
        </Button>
      </div>
    </Modal>
  );
};

export default CustomModal;
