import { getRequest, ResponseDI } from "../services/urlService";
import { commonUrl } from "../../base/services/urlService";
import { SiteMapItemDI } from "../stores/AppStore";

interface MenuCat {
  text: string;
  link: string | null;
  isNewTab: boolean;
  links: Array<MenuCat>;
}

interface StepItem {
  text: string;
  link: string;
  isNewTab: boolean;
}

interface SubLinkItemI {
  text: string;
  link: string;
  isNewTab: boolean;
  links: null;
}

interface SubLinkI {
  text: string;
  link: null;
  isNewTab: boolean;
  links: Array<SubLinkItemI>;
}

interface SubLinksListI {
  subLinksList: Array<SubLinkI>;
  //   onCheck: (id: string | number, checked: boolean) => void
}

interface ListsFil {
  Lists: Array<ListsFilObj>;
  maxShow: number;
}

interface ListsFilObj {
  l: Array<ListsFilItem>;
  t: string;
  show: boolean;
}

interface ListsFilItem {
  id: number;
  name: string;
  title: string;
  count: number;
}

interface ArticlesGalleryItemI {
  title: string;
  date: string | null;
  image: string;
  text: string;
  link: string;
  isNewTab: boolean;
}

interface MovieItemI {
  vimeo_movie_url: string;
  title: string;
  movie: string;
  image: string;
}

interface InitDataDI {
  header: {
    logoUrl: string;
    title: string;
    subTitle: string;
    menuCats: Array<MenuCat>;
  };
  footer: {
    logoUrl: string;
    menuCats: Array<MenuCat>;
  };
  steps: Array<StepItem>;
  links: Array<SubLinkI>;
  searchFields: Array<ListsFilObj>;
  articles: Array<ArticlesGalleryItemI>;
  movieGallery: Array<MovieItemI>;
  showMovieGallery: boolean;
  siteMap: Array<SiteMapItemDI>;
  showFreeSearch: boolean;
  imageMaxWidth: number;
  imageMaxHeight: number;
  movieGalleryTitle: string;
  linksTitle: string;
  prjectStepsTitle: string;
  relatedArticlesTitle: string;
    searchTitle: string;
    lastFocusedElement: any;
}

const initData = async () => {
  const url = `${commonUrl}/api/home/initData`;
  const request = await getRequest<ResponseDI<InitDataDI>>(url);
  return request.result;
};

export default initData;
